<template>
  <div class="formContainer">
    <div class="formBox">
      <div class="aimTitle">
        <el-tag class="ml-2" type="success">传感器配置</el-tag>
      </div>
      <el-form
        :model="form"
        :label-width="labelWidth"
        ref="elForm"
      >
        <!--        <el-row :gutter="24">-->

        <!--          <el-col :span="12" >-->
        <!--          </el-col>-->

        <!--        </el-row>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="温度湿度烟雾api"
          prop="aiUrl"
          :rules="[{ required: true, message: '温度湿度烟雾api 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.aiUrl"
            placeholder="请输入 温度湿度烟雾api"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="水浸api"
          prop="diUrl"
          :rules="[{ required: true, message: '水浸api 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.diUrl"
            placeholder="请输入 水浸api"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="温度警戒值"
          prop="temperatureBasic"
          :rules="[{ required: true, message: '温度警戒值 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.temperatureBasic"
            placeholder="请输入 温度警戒值"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="湿度警戒值"
          prop="humidityBasic"
          :rules="[{ required: true, message: '湿度警戒值 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.humidityBasic"
            placeholder="请输入 湿度警戒值"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="烟雾警戒值"
          prop="smokeBasic"
          :rules="[{ required: true, message: '烟雾警戒值 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.smokeBasic"
            placeholder="请输入 烟雾警戒值"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="定时检测 cpu 内存(毫秒)"
          prop="timingOne"
          :rules="[{ required: true, message: '定时检测 cpu 内存(毫秒) 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.timingOne"
            placeholder="请输入 定时检测 cpu 内存(毫秒)"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="定时检测 传感器(毫秒)"
          prop="timingTwo"
          :rules="[{ required: true, message: '定时检测 传感器(毫秒) 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.timingTwo"
            placeholder="请输入 定时检测 传感器(毫秒)"
          >
          </el-input>
        </el-form-item>
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>
        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from "vue";
import { sensorconfigData, submitData } from "@/api/motorroom/sensorConfig";
import { ElMessage } from "element-plus";

export default {
  name: "sensorConfig",
  setup() {

    const option = reactive({
      labelWidth: "220px",
      form: {}
    });
    const elForm = ref(null);
    onMounted(() => {
      sensorconfigData().then(res => {
        if (res.data.success) {
          option.form = res.data.data;
        }
      });
    });

    function addForm() {
      elForm.value.validate((valid) => {
        if (valid) {
          let { aiUrl, diUrl, temperatureBasic, humidityBasic, smokeBasic, timingOne, timingTwo } = option.form;
          submitData({ aiUrl, diUrl, temperatureBasic, humidityBasic, smokeBasic, timingOne, timingTwo }).then(res => {
            if (res.data.success) {
              ElMessage({
                type: "success",
                message: "操作成功！"
              });
            }
          });

        }
      });
    }

    return {
      ...toRefs(option),
      elForm,
      addForm
    };
  },
  data() {
    return {};
  }

};
</script>

<style scoped>
.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;;
}

.formBox {
  width: 800px;
}

.aimTitle {
  text-align: left;
  margin-bottom: 30px;
}
</style>