import axios from "@/api/axios"; // 引用axios
//获取传感器数据
export const sensorconfigData = (params = {}) => {
  return axios({
    url: "/api/sensorBasic/getNewSensorBasic",
    method: "post",
    params: params
  });
};
//提交传感器数据
export const submitData = (params) => {
  return axios({
    url: "/api/sensorBasic/addData",
    method: "post",
    params: params
  });
};
